import React, { useEffect } from 'react'
import useSafeNavigation from 'hooks/useSafeNavigation'
import { withAppLayout } from 'layouts/AppLayout'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getServerSideProps = async () => {
  return {
    props: {
      currentUser: {}
    }
  }
}

const Index = (): JSX.Element => {
  const { safeReplace } = useSafeNavigation()

  useEffect(() => {
    safeReplace('/tasks/my-tasks')
  })

  return <></>
}

export default withAppLayout(Index)
